import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-css-modules';
import IsLoading from 'common/IsLoading';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format, compareDesc } from 'date-fns';

import useViewport from '../../../../../utils/useViewportHelper';
import trackEvent from '../../../../../services/AnalyticsService';
import Button from '../../../../../shared/components/Button/Button';
import styles from './MembershipDocuments.module.scss';
import downloadIcon from '../../../../../assets/images/button/download-icon.svg';
import downloadIconDark from '../../../../../assets/images/button/download-icon-dark.svg';
import { GetDocument } from '../../../../../services/MyRacService';
import { getMessage } from '../../../../../services/MessageService';
import { documentErrors } from '../../../../../constants/cms/messageConstants';
import { DOCUMENT_TYPE_BOOKLET } from '../../../../../constants/documentConstants';
import PopupModal from '../../../../../components/PopupModal/PopupModal';

const bem = block(styles);

block.setSettings({
  throwOnError: true,
  modifierDelimiter: '--',
});

const {
  string,
  bool,
  shape,
  number,
  arrayOf, // Get prop types
} = PropTypes;

export const MembershipDocuments = (props) => {
  const {
    pageModel,
    documents,
    customerId,
    policyId, // policy
  } = props;
  const [errorText, setErrorText] = useState(undefined);
  const viewportHelper = useViewport();
  const documentsLoaded = documents.documents.payload;
  const { hasError } = documents.documentError;
  const navigate = useNavigate();
  const seeDocuments = () => {
    navigate(pageModel.tileContent.button.callToAction.url);
    trackEvent('MyRAC_Overview_view_documents');
  };

  const handleError = (errText) => {
    setErrorText(errText);
  };
  const documentDownloadRef = useRef();

  const getDocument = (e, doc, custId, polId) => {
    e.preventDefault();
    const documentType = doc.DocumentType === 'Booklet' ? doc.DocumentType.toLowerCase() : polId;
    return GetDocument(custId, documentType, doc.DocumentId)
      .then((blob) => {
        // This part is for IE, we can bin if not needed.
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob.payload, `${doc.Name}.pdf`);
        } else {
          const file = new Blob([blob.payload], {
            type: 'application/pdf',
          });
          // Logic added as window.open() is not reliable in all browser if not directly called by a user event
          documentDownloadRef.current.href = URL.createObjectURL(file);
          documentDownloadRef.current.download = `${doc.Name}.pdf`;
          documentDownloadRef.current.click();
        }
      })
      .catch((err) => {
        if (err) {
          handleError(getMessage(documentErrors.DOWNLOAD_ERROR));
        }
      });
  };

  const closeErrorDialog = () => {
    setErrorText(undefined);
  };

  const getWidgetStyle = () => {
    const widgetStyles = {};
    if (viewportHelper.isBootstrapSm) {
      if (pageModel.backgroundImageMobile && pageModel.backgroundImageMobile.url) {
        widgetStyles.backgroundImage = `url(${pageModel.backgroundImageMobile.url})`;
        widgetStyles.backgroundSize = 'cover';
      }
      if (pageModel.backgroundColourMobile) {
        widgetStyles.backgroundColor = `${pageModel.backgroundColourMobile}`;
      }
    }

    if (!viewportHelper.isBootstrapSm) {
      if (pageModel.backgroundImageDesktop && pageModel.backgroundImageDesktop.url) {
        widgetStyles.backgroundImage = `url(${pageModel.backgroundImageDesktop.url})`;
        widgetStyles.backgroundSize = 'cover';
      }
      if (pageModel.backgroundColourDesktop) {
        widgetStyles.backgroundColor = `${pageModel.backgroundColourDesktop}`;
      }
    }

    return widgetStyles;
  };

  const getButtonStyle = (buttonVars) => {
    const buttonStyles = {
      backgroundColor: buttonVars.buttonColour,
      color: buttonVars.textColour,
      display: 'block',
    };

    if (buttonVars.isBackgroundColorTransparent) {
      buttonStyles.backgroundColor = 'transparent';
      buttonStyles.border = `1px solid ${buttonVars.buttonColour}`;
    }

    return buttonStyles;
  };

  const orderDocuments = (documentsArray) => documentsArray.sort((a, b) => {
    const result = compareDesc(new Date(a.DocumentDate), new Date(b.DocumentDate));
    return result;
  });

  const getLatestTerms = (documentsArray) => (documentsArray ? documentsArray.sort((a, b) => {
    const result = compareDesc(new Date(a.DocumentDate), new Date(b.DocumentDate));
    return result;
  }).slice(0, 1) : []);

  const getDocuments = (docs) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    [
      ...orderDocuments(docs.filter((doc) => doc.DocumentType !== DOCUMENT_TYPE_BOOKLET)).slice(0, 2),
      ...getLatestTerms(docs.filter((doc) => doc.DocumentType === DOCUMENT_TYPE_BOOKLET)),
    ].map((document) => (
      <div className={bem('document')} key={uniqid()}>
        <div className={bem('document-title-wrapper')}>
          <span className={bem('document-title')}>{document.Name}</span>
          <button type="button" className={bem('download-button')} onClick={(e) => getDocument(e, document, customerId, policyId)}>
            <img className={bem('download-icon')} src={viewportHelper.isBootstrapSm ? downloadIconDark : downloadIcon} alt="download" />
          </button>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a role="button" className={bem('display-anchor')} href="#" ref={documentDownloadRef}>
            This is required for attaching the react ref for download as window.open() is not reliable in some browsers if not directly linked to a user event
          </a>
        </div>
        <div className={bem('date')}>
          <span>{format(new Date(document.DocumentDate), 'dd/MM/yyyy')}</span>
        </div>
      </div>
    ));

  const getDocumentsWrapper = () => <div className={bem('documents')}>{getDocuments(documents.documents.payload)}</div>;

  const getTileData = () => (
    <>
      <div className={bem('content-container')}>
        <h2 className={bem('title')}>{pageModel.tileContent.myDocumentsHeading}</h2>
        {documentsLoaded && documentsLoaded.length ? (
          getDocumentsWrapper(documents.documents.payload)
        ) : (
          <strong>{pageModel.tileContent.failureMessage}</strong>
        )}
      </div>
      <div className={bem('button-container')}>
        <Button
          buttonText={pageModel.tileContent.button.buttonText}
          onClickHandler={seeDocuments}
          chevronWhite
          ghostButton
          outlineBlack={viewportHelper.isBootstrapSm}
          colorBlack
          small
          fullWidth={!viewportHelper.isBootstrapSm}
          buttonStyles={!viewportHelper.isBootstrapSm ? getButtonStyle(pageModel.tileContent.button) : null}
        />
      </div>
    </>
  );

  return (
    <>
      <section className={bem()} style={getWidgetStyle(pageModel)}>
        {Object.keys(documents.documents).length || hasError ? getTileData() : ''}
        {!Object.keys(documents.documents).length && !hasError ? (
          <div className={bem('loader')}>
            <IsLoading />
            <span>{pageModel.tileContent.progressiveLoadingText}</span>
          </div>
        ) : (
          ''
        )}
      </section>
      {errorText
      && (
      <PopupModal
        innerCloseButton
        modalOk="Ok"
        action={closeErrorDialog}
        resultText={errorText}
        displayDialogAtTopQuater
        alignBodyCenter
        mediumFont
      />
      )}
    </>
  );
};

MembershipDocuments.propTypes = {
  pageModel: shape({
    content_Type_UID: string,
    tags: string,
    backgroundColourDesktop: string,
    backgroundImageDesktop: shape({
      url: string,
      fileName: string,
      title: string,
    }),
    backgroundColourMobile: string,
    backgroundImageMobile: shape({
      url: string,
      fileName: string,
      title: string,
    }),
    rows: number,
    columns: number,
    isCollapsible: bool,
    isCollapsed: bool,
    tileContent: shape({
      myDocumentsHeading: string,
      button: shape({
        buttonColour: string,
        buttonText: string,
        textColour: string,
        isBackgroundColorTransparent: bool,
        callToAction: shape({
          url: string,
          actionType: string,
        }),
      }),
      progressiveLoadingText: string,
      failureMessage: string,
      collapsedHeading: string,
      expandedHeading: string,
      title: string,
      tags: string,
    }),
  }),
  documents: shape({
    documents: shape({
      correlationId: string,
      sessionId: string,
      payload: arrayOf(
        shape({
          DocumentId: string,
          Name: string,
          DocumentDate: string,
          DocumentType: string,
          documentLocation: string,
        }),
      ),
      error: string,
    }),
    documentError: shape({
      hasError: bool,
    }),
  }),
  policyId: number,
  customerId: string,
};

MembershipDocuments.defaultProps = {
  pageModel: {
    content_Type_UID: '',
    tags: null,
    backgroundColourDesktop: '',
    backgroundImageDesktop: {
      url: '',
      fileName: '',
      title: '',
    },
    backgroundColourMobile: '',
    backgroundImageMobile: {
      url: '',
      fileName: '',
      title: '',
    },
    rows: undefined,
    columns: undefined,
    isCollapsible: undefined,
    isCollapsed: undefined,
    tileContent: {
      myDocumentsHeading: '',
      button: {
        buttonColour: '',
        buttonText: '',
        textColour: '',
        isBackgroundColorTransparent: undefined,
        callToAction: {
          url: '',
          actionType: '',
        },
      },
      progressiveLoadingText: '',
      failureMessage: '',
      collapsedHeading: '',
      expandedHeading: '',
      title: '',
      tags: '',
    },
  },
  documents: {
    documents: {
      correlationId: '',
      sessionId: '',
      payload: [],
      error: '',
    },
    documentError: undefined,
  },
  policyId: undefined,
  customerId: '',
};

const mapStateToProps = (state) => ({
  documents: state.documents,
  customerId: state.customer.custInfo.Id,
  policyId: state.policy.policyInfo.PolicyId,
});

export default connect(mapStateToProps)(MembershipDocuments);
