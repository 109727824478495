import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetRenewalDirectDebitStatus } from '../../../services/MyRacService';
import { RENEWAL_CONFIRMATION, RENEWAL_ERROR } from '../../../constants/routeConstants';
import styles from './DirectDebitForm.module.scss';
import Button from '../../../shared/components/Button/Button';

const DirectDebitForm = ({ payload, onError, onInvalid, renewalToken, children }) => {
  const navigate = useNavigate();

  // Currently isCompactVer only used for Direct Debit monthly
  const isCompactVer = !!children;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState();
  const [count, setCount] = useState(0);
  const [validationError, setValidationError] = useState({
    invalidName: false,
    invalidAccountNo: false,
    invalidSortCode: false,
  });

  const formValidation = () => {
    const invalidName = !state?.form?.Name;
    const invalidAccountNo = !state?.form?.AccountNo || state?.form?.AccountNo.length < 8;
    const invalidSortCode = !state?.form?.SortCode || state?.form?.SortCode.length < 6;

    setValidationError({ invalidName, invalidAccountNo, invalidSortCode });

    return !invalidName && !invalidAccountNo && !invalidSortCode;
  };

  const handleInputChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    const updatedInputs = { ...state?.form };
    updatedInputs[name] = value;

    // if the user starts entering data in a field with an error, the error should disappear
    const updatedValidationError = validationError;
    updatedValidationError[`invalid${name}`] = false;
    setValidationError(updatedValidationError);

    setState((prev) => ({ ...prev, form: updatedInputs }));
  };

  const validateTextEntry = (e) => {
    // Only allow alpha letters, hyphens or apostrophes to be entered into name field
    if (!/^[a-zA-Z-' ]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateNumericEntry = (e) => {
    // Only allow numeric values to be entered into field
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const validateSubmit = async () => {
    try {
      const response = await SetRenewalDirectDebitStatus(
        payload.CustomerId,
        payload.MembershipNumber,
        payload.PolicyNumber,
        payload.Price,
        payload.RenewalRiskVersion,
        renewalToken,
        state?.form?.Name,
        state?.form?.AccountNo,
        state?.form?.SortCode,
      );
      // Payload determines if account details are valid
      if (response?.payload) {
        const { isDirectDebitSuccessful, maxAttemptNumberReached } = response.payload;
        setLoading(false);

        if (isDirectDebitSuccessful) {
          // Submit details to SSP and navigate to confirmation page
          window.globalDataLayer.MyRAC_Upgrades_Confirmed = payload.Amendments?.length > 0 ? payload.Amendments.map((upgrade) => upgrade.title) : [];
          navigate(RENEWAL_CONFIRMATION, { state: { isDirectDebit: true, ...payload }, replace: true });
        }
        if (maxAttemptNumberReached || count === 3) {
          // Navigate to error page if member submits incorrect details on 3 occasions
          navigate(RENEWAL_ERROR, { state: { isPaymentError: true, ...payload }, replace: true });
        } else {
          // Invalid account details so boolean returned to parent component to showInvalidModal
          onInvalid(true);
        }
      }
    } catch (e) {
      // Request failed so boolean returned to parent component to showErrorModal
      onError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Return true to submit values or false which invokes validation errors on form
    const isValid = formValidation();
    // Filter true values returned from 'state.form' and count to make sure all fields are completed
    const formValues = state?.form && Object.values(state?.form).filter((item) => item).length;
    // Combine checks
    if (formValues === 3 && isValid) {
      setCount(count + 1);
      setLoading(true);
      validateSubmit();
    }
  };

  return (
    <div className={isCompactVer ? styles.compactVersion : styles.wrapper}>
      <form id="DirectDebitForm" className={isCompactVer ? '' : 'w-100 px-4 px-md-5'}>
        {!payload?.MonthlyLongerTermPolicy ? (
          <div className="fs-3 mb-3">
            £{payload?.Price?.toFixed(2)}
          </div>
        ) : <br />}
        <label htmlFor="Name" className={isCompactVer ? 'form-label w-100 mb-4' : 'form-label w-100'}>
          {isCompactVer ? '' : 'Account holderʼs name'}
          <input
            aria-label="Account holder name"
            name="Name"
            onPaste={validateTextEntry}
            onKeyPress={validateTextEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidName && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="50"
            placeholder={isCompactVer ? 'Account holderʼs name' : ''}
          />
          <small className={validationError?.invalidName ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter name on the account
          </small>
        </label>
        {!isCompactVer && <br />}
        <label htmlFor="AccountNo" className={isCompactVer ? 'form-label col-12 col-md-8 mb-4 mb-md-0' : 'form-label w-100'}>
          {isCompactVer ? '' : 'Account number'}
          <input
            aria-label="Account number"
            name="AccountNo"
            onPaste={validateNumericEntry}
            onKeyPress={validateNumericEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidAccountNo && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="8"
            placeholder={isCompactVer ? 'Account number' : ''}
            inputMode="numeric"
          />
          <small className={validationError?.invalidAccountNo ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter a valid account number
          </small>
        </label>
        {!isCompactVer && <br />}
        <label htmlFor="SortCode" className={isCompactVer ? 'form-label col-12 col-md-3 offset-md-1 mb-3' : 'form-label w-100 mb-4'}>
          {isCompactVer ? '' : 'Sort code'}
          <input
            aria-label="Sort code"
            name="SortCode"
            onPaste={validateNumericEntry}
            onKeyPress={validateNumericEntry}
            onChange={handleInputChange}
            className={`${validationError?.invalidSortCode && 'is-invalid'} form-control form-control-lg`}
            type="text"
            maxLength="6"
            placeholder={isCompactVer ? 'Sort code' : ''}
            inputMode="numeric"
          />
          <small className={validationError?.invalidSortCode ? 'd-flex invalid-feedback' : 'd-none'}>
            Please enter a valid sort code
          </small>
        </label>
        {/* only one case right now when this component uses children (isCompactVer) - DD monthly users */}
        {children}
        <Button onClickHandler={handleSubmit} loading={loading} buttonText={isCompactVer ? 'Continue' : 'Pay now'} chevronWhite />
      </form>
    </div>
  );
};

export default DirectDebitForm;
