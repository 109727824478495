import React from 'react';
import { format } from 'date-fns';
import styles from './PaymentSummary.module.scss';

const PaymentSummary = ({ pageModel }) => (
  <div className={styles.container}>
    <h3>Payment summary</h3>
    <ul>
      <li>Cover starts on: <span>{format(new Date(pageModel?.newCoverStartDate), 'd MMM yyyy')}</span></li>
      <li>Your Direct Debit starts on: <span>{format(new Date(pageModel?.basketContent?.directDebitStartDate), 'd MMM yyyy')}</span></li>
      <li>First instalment: <span>£{pageModel?.basketContent?.firstInstalmentPrice.toFixed(2)}</span></li>
      <li>Remaining instalments: <span>£{pageModel?.basketContent?.remainingInstalmentPrice.toFixed(2)}</span></li>
      <li>Total cost: <span>£{pageModel?.price.toFixed(2)}</span></li>
    </ul>
  </div>
);

export default PaymentSummary;
