import {
  SET_RENEWAL_PAGE_MODEL,
  SET_RENEWAL_FEATURE_TAG,
  SET_RENEWAL_PAYMENT_PAYLOAD,
  SELECT_RENEWAL_UPGRADE,
  REMOVE_RENEWAL_UPGRADE,
  SELECT_RENEWAL_FLR,
  REMOVE_RENEWAL_FLR,
  SET_RENEWAL_VEHICLES,
  CHANGE_RENEWAL_VEHICLE,
  SET_RENEWAL_AUTORENEW,
  SET_RENEWAL_PAPERLESS,
} from 'constants/renewalConstants';

export function setRenewalPageModel(pageModelPayload) {
  return {
    type: SET_RENEWAL_PAGE_MODEL,
    payload: pageModelPayload,
  };
}

export function setRenewalFeatureTag(featureTagPayload) {
  return {
    type: SET_RENEWAL_FEATURE_TAG,
    payload: featureTagPayload,
  };
}

export function setRenewalPaymentPayload(renewalJourneyPayload) {
  return {
    type: SET_RENEWAL_PAYMENT_PAYLOAD,
    payload: renewalJourneyPayload,
  };
}

export function selectRenewalUpgrade(upgradeToAddPayload) {
  return {
    type: SELECT_RENEWAL_UPGRADE,
    payload: upgradeToAddPayload,
  };
}

export function removeRenewalUpgrade(upgradeToDeletePayload) {
  return {
    type: REMOVE_RENEWAL_UPGRADE,
    payload: upgradeToDeletePayload,
  };
}

export function selectRenewalFreeLoyaltyReward(freeLoyaltyRewardToAddPayload) {
  return {
    type: SELECT_RENEWAL_FLR,
    payload: freeLoyaltyRewardToAddPayload,
  };
}

export function removeRenewalFreeLoyaltyReward() {
  return {
    type: REMOVE_RENEWAL_FLR,
  };
}

export function setRenewalVehicles(vehiclesPayload) {
  return {
    type: SET_RENEWAL_VEHICLES,
    payload: vehiclesPayload,
  };
}

export function changeRenewalVehicle(vehiclesPayload) {
  return {
    type: CHANGE_RENEWAL_VEHICLE,
    payload: vehiclesPayload,
  };
}

export function setRenewalAutoRenew(booleanPayload) {
  return {
    type: SET_RENEWAL_AUTORENEW,
    payload: booleanPayload,
  };
}

export function setRenewalPaperless(booleanPayload) {
  return {
    type: SET_RENEWAL_PAPERLESS,
    payload: booleanPayload,
  };
}
