import React from 'react';
import { format } from 'date-fns';
import ReactHtmlParser from '@orrisroot/react-html-parser';
import styles from './DirectDebitScreen.module.scss';

const InfoBox = ({ data, onClickHandler, image, displayCollectionInfo }) => (
  <div className={styles.info}>
    <div className={styles.greybox}>
      {image || ''}
      <p className="mt-3">{data.DirectDebitPage?.directDebitTermsAndConditionsModel?.header}</p>
      {ReactHtmlParser(data.DirectDebitPage?.directDebitTermsAndConditionsModel?.body)}
      <p><span onClick={onClickHandler} aria-hidden="true">Direct Debit instructions & guarantee</span></p>
      {displayCollectionInfo && (
        <p className={styles.date}>
          Payment to be collected on or around {data.PaymentDate && format(new Date(data.PaymentDate), 'd MMM yyyy')}
        </p>
      )}
    </div>
  </div>
);

export default InfoBox;
